import {Click2d} from "../../Click2d";
import {useItem} from "../../BackPack";
import {addDocument} from "../../documents";

export const imgStackStyle = {
    maxHeight: '100vh',
    maxWidth: '100vw',
    position: "absolute"
};

const width = 544;
const height = 426;
export const garageKey = "garage";

export const RedBox = () => {
    const [hasKey, setHasKey] = useItem(garageKey);
    return (<div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
        }}
    >
        <div
            style={{
                minHeight: height+'px',
                minWidth: width+'px',
                position: 'relative',
            }}
        >
            <img
                style={{
                    height:  height+'px',
                    width: width+'px',
                }}
                src={require("./redBox.png").default}/>
            {!hasKey && <img
                style={{
                    left: '230px',
                    top: '130px',
                    width: '76px',
                    height: '36px',
                    position: 'absolute',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setHasKey(true);
                }}
                src={require("./key.png").default}/>}
            <Click2d
                style={{
                    left: '80px',
                    top: '170px',
                    width: '180px',
                    height: '130px',
                }}
                onClick={() => {
                    addDocument("liquidateurPhoto")
                }}
            ></Click2d>
            <Click2d
                style={{
                    left: '260px',
                    top: '170px',
                    width: '200px',
                    height: '150px',
                }}
                onClick={() => {
                    addDocument("billet")
                }}
            ></Click2d>
        </div>
    </div>)
}