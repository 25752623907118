import {useLoader} from "@react-three/fiber";
import * as THREE from "three";
import {TextureLoader} from "three";
import {ClickZone, To2d, ToScene} from "../../Zone";
import {useCState} from "../../CState";
import {slotAnswerKey} from "../Mac/slot";
import {useItem} from "../../BackPack";
import {addDocuments} from "../../documents";
import {useEffect} from "react";
import {addClueStep} from "../../clues";

export const AssociateUnlockedKey = "AssociateUnlockedKey";

export function Oncle() {
    // console.log(" :", ref)
    const colorMap = useLoader(TextureLoader, require('./Oncle.png').default);
    colorMap.wrapS = THREE.RepeatWrapping;
    colorMap.repeat.x = -1
    const printed = useLoader(TextureLoader, require('./printed.png').default);
    printed.wrapS = THREE.RepeatWrapping;
    printed.repeat.x = -1

    const [isPrinted, _] = useCState(slotAnswerKey, false);
    const [isAssociateOpened, _2] = useCState(AssociateUnlockedKey, false);
    // console.log("isPrinted", isPrinted)

    useEffect(() => {
        addClueStep("oncle")
    },[])

    return <group>

        <mesh rotation={[0, 0, 0]}>
            <sphereGeometry args={[500, 60, 40]}/>
            <meshStandardMaterial map={colorMap} side={THREE.DoubleSide}/>
        </mesh>
        {isPrinted && <mesh rotation={[0, 0, 0]}>
            <sphereGeometry args={[499, 59, 39]}/>
            <meshStandardMaterial map={printed} transparent='true' side={THREE.DoubleSide}/>
        </mesh>}

        {!isAssociateOpened && <To2d name={"Discs"} y={-0.4} z={215}/>}
        {isAssociateOpened && <ToScene name={"Associate"} y={-0.4} z={215}/>}
        <ToScene name={"Paint2"} y={-0.4} z={200}/>
        <To2d name={"Contract"} y={-1.2} z={95}/>
        <To2d name={"Mac"} y={-1.2} z={350}/>
        <To2d name={"Avenant3"} y={-1.7} z={140}/>
        <To2d name={"Avenant2"} y={-1} z={328}/>
        <ClickZone y={0.3} z={60} callback={() => {
            addDocuments(["avenant1"]);
        }

        }/>
        <ClickZone y={-0.4} z={123} callback={() => {
            addDocuments(["avenant4"]);
        }
        }/>
        {isPrinted && <ClickZone y={-1.0} z={13} callback={() => {
            addDocuments(["poeme", "projetDissolution"]);
        }
        }/>
        }
        {isPrinted && <To2d name={"Portraits"} y={0.2} z={22}/>
        }

    </group>
}