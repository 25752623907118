
export const imgStackStyle = {
    maxHeight: '100vh',
    maxWidth: '100vw',
    position: "absolute"
};
// La société est active
//       La société est dissoute
//       La société est en faillite

// active 6
// dissoute 8
// enfaillite 10
// 24
// 8 * 8 = 64

// IaNendRJ
// ETEcEZSG
// CiQCFssT
// QKIfaiBQ
// ZORouFAX
// GZtZViYI
// llFJteIK
// LMvOiteS
//
// IANENDRJ
// ETECEZSG
// CIQCFSST
// QKIFAIBQ
// ZOROUFAX
// GZTZVIYI
// LLFJTEIK
// LMVOITES



export const Triphase = () => {


    return (<div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
        }}

    >
        <img
            style={imgStackStyle}
            src={require("./triphase.png").default}/>
    </div>)
}