import process from "process";
import {Puzzle} from "./scene3/Puzzle/Puzzle";

export let showSquare = 0;
export let completeResponse = false;
export let default2D = "Welcome";
export let defaultScene = "Chambre";
export let sound = true;
export let initItems = null;
export let initCState = null;
export let serverUrl = "https://api.realityquest.city";
export let api = "https://seriousgame.realityquest.city/api";
export let localeDb = false
export let showAllDocs = false;

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
// sudo /etc/init.d/mysql start
export default function isDev() {
    // serverUrl = "http://localhost:3000";
    // localeDb = false; in util.js
     return false;
    // return development;
}

const gameStartedInit = Date.now() - 2 * 60 * 1000;

if (isDev()) {
    serverUrl = "http://localhost:3000";
    showSquare = 0.2;
    localeDb = true;
    // showAllDocs = true;
    completeResponse = true;
    // default2D = "Mac";
    default2D = "End";
    // default2D = "Welcome";
    // default2D = "Discs";
    // default2D = "Discs";
    defaultScene = "Remise";
    sound = false;
    // initItems = ["Smartphone","Folder"];
    // initItems = [];
    initCState = {
    //     "backPackItemsKey" : ["Smartphone","Folder"],
    //     "scene": "Paint2",
    //     "answered": true,
    //     // "oncleUnlockedKey": false,
    //     "desktopStep": "ListEmails",
    //     "smartphoneScreen": "desktop",
    //     "smartphoneAddress": "rue de Rechtsanwalt 4, Genève",
    //     "gameStarted":gameStartedInit,
    //     "gameStopped": null,
    //     // "slotAnswer": true,
    //     "AssociateUnlockedKey": true,
    //     // "calcSuccess": true,
    //     // "oncleEmailAnswer": true,
    };
}