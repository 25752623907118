import {useEffect, useRef, useState} from "react";
import {showSquare} from "../../config";
import {useCState} from "../../CState";
import {useSetRecoilState} from "recoil";
import {page2dState} from "../../Page2d";
import {sceneKey, screenState} from "../../Scene";
import {addClueStep} from "../../clues";


export const lockerUnlockedKey = "lockerUnlocked";

const letters = "123456789".split("");

function underscorePad(code, length = 7) {
    return code.padStart(length, "_")
}

let padStyle = {
    height: '60px',
    width: '80px',
    cursor: 'pointer',
    // background: "rgba(213,70,70,0.39)",
};

export const Locker = () => {

    const [code, setCode] = useState("")
    const [error, setError] = useState(false)
    const [unlocked, setUnlocked] = useCState(lockerUnlockedKey);
    const setPage2d = useSetRecoilState(page2dState);

    let color = "white"
    if (error)
        color = "#ff6f6f"
    if (unlocked)
        color = "#b3ffb3"

    return (<div
        style={{
            display: 'flex',
            position: "relative",
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
        }}
    >
        <div
            style={{
                position: "absolute",
                width: '438px',
                height: '800px'
            }}
        >
            <img
                src={require("./locker.png").default}
                style={{
                    position: "absolute",
                    width: '438px',
                    height: '800px'
                }}
            />
            <div
                style={{
                    fontFamily: 'Digital-7',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    top: '100px',
                    left: '72px',
                    color,
                    width: '275px',
                    position: 'absolute',
                    fontSize: '80px',
                }}
            >
                {underscorePad(code)}
            </div>
            <div
                style={{
                    position: 'absolute',
                    left: '72px',
                    top: '306px',
                    width: '295px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    columnGap: '25px',
                    rowGap: '27px',
                    }}
            >
                {
                    "123456789".split("").map((letter, index) => {

                        return <div
                            key={index}
                            style={padStyle}
                            onClick={() => {
                                setError(false);
                                if (code.length < 7)
                                    setCode(code + letter)
                            }}
                            >
                        </div>
                    })
                }
                <div
                    key={"clear"}
                    style={padStyle}
                    onClick={() => setCode("")}
                ></div>
                <div
                    key={"0"}
                    style={padStyle}
                    onClick={() => {
                        setError(false);
                        if (code.length < 7)
                            setCode(code + "0")
                    }}
                ></div>
                <div
                    key={"validate"}
                    style={padStyle}
                    onClick={() => {
                        if (code === "4000000") {
                            setUnlocked(true);
                            addClueStep("locker");
                            setTimeout(() => {
                                setPage2d("RedBox");
                            }, 1000)
                        }else
                            setError(true);
                    }}
                ></div>
            </div>
        </div>
    </div>)
}