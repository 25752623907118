import {useInterval} from "../../Hooks";
import {useState} from "react";


async function getNumberOfLoadedCacheItems() {
    const cacheNames = await caches.keys();

    let totalItems = 0;

    for (const cacheName of cacheNames) {
        const cache = await caches.open(cacheName);
        const cachedRequests = await cache.keys();
        totalItems += cachedRequests.length;
        // console.log(`Cache "${cacheName}" contains ${cachedRequests.length} items.`);
    }
    return totalItems;
}

export const GameLoader = () => {
    const cachedItemsTotal = 330;
    const [cacheItemsLoaded, setCacheItemsLoaded] = useState(0);


    useInterval(() => {
        getNumberOfLoadedCacheItems().then((res) => {
            // console.log("numberOfLoadedCacheItems: ", res);
            setCacheItemsLoaded(res);
        });
    }, 1000);

    if (cacheItemsLoaded >= cachedItemsTotal) {
        return (
            <div style={{color: '#97ff85',
                textAlign: "center", margin: "10px"}}>
                Game loaded with success !
            </div>
        );
    }

    const percent = Math.min(Math.floor((cacheItemsLoaded / cachedItemsTotal) * 100), 100);
    return (
        <div style={
            {
                color: '#ff7373',
                textAlign: "center",
                margin: "10px"
            }

        }>
            <img src={require("./loading.gif").default} style={{width: "35px", verticalAlign: "middle"}}/>
            Game Loading {percent}%
            <img src={require("./loading.gif").default} style={{width: "35px", verticalAlign: "middle"}}/>
        </div>
    );
}