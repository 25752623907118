import {Click2d} from "../../Click2d";
import {addDocuments} from "../../documents";

export const imgStackStyle = {
    maxHeight: '100vh',
    maxWidth: '100vw',
    position: "absolute"
};


export const Avenant3 = () => {
    return (<div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
        }}
    >
        <div
            style={{
                minHeight: '534px',
                minWidth: '800px',
                position: 'relative',
            }}
        >
        <img
            style={{
                height: '534px',
                width: '800px',
            }}
            src={require("./avenant3.png").default}/>
        <Click2d
            style={{
                left: '392px',
                top: '145px',
                width: '282px',
                height: '277px',
            }}
            onClick={() => {
                // console.log("click")
                addDocuments(["avenant3"])
            }}
        ></Click2d>
        </div>
    </div>)
}