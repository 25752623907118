
export const imgStackStyle = {
    maxHeight: '100vh',
    maxWidth: '100vw',
    position: "absolute"
};


export const Oncle = () => {


    return (<div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
        }}
    >
        <img
            style={imgStackStyle}
            src={require("./oncle.png").default}/>
    </div>)
}