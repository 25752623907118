import {useCState} from "../../../CState";
import {Desktop} from "./Desktop";
import {useState} from "react";
import {Call} from "./Call";
import {Map} from "./Map";
import {Message} from "./Message";
import {factsUnlockedKey} from "../../../scene4/Calc/Calc";
import {lockerUnlockedKey} from "../../../scene5/Locker/Locker";

export const answeredKey = "answered";
export const oncleEmailAnsweredKey = "oncleEmailAnswered";
export const garageAnsweredKey = "garageAnswered";
export const smartphoneScreenKey = "smartphoneScreen";

const Content = () => {

    const [answered, setAnswered] = useCState(answeredKey);
    const [step, setStep] = useCState(smartphoneScreenKey, "welcome");

    const [calcSuccess, _3] = useCState(factsUnlockedKey);
    const [answeredOncle, setAnsweredOncle] = useCState(oncleEmailAnsweredKey);

    const [lockerSuccess, _5] = useCState(lockerUnlockedKey);
    const [garageAnswered, setGarageAnswer] = useCState(garageAnsweredKey);

    if (!answered
        || (calcSuccess && !answeredOncle)
        || (lockerSuccess && !garageAnswered))
        return (<div
            onClick={() => {
                setAnswered(true)
                if (calcSuccess)
                    setAnsweredOncle(true)
                if (lockerSuccess)
                    setGarageAnswer(true)

                setStep("call")
            }}
            style={{
                width: '324px',
                height: '650px',
                position: 'relative'
            }}>
            <img style={{
                // width: '436px',
                height: '650px',
            }}
                 src={require("./answer.png").default}
            />
            <img style={{
                height: '100px',
                position: 'absolute',
                top: '400px',
                left: '125px'
            }}
                 src={require("./arrow.gif").default}
            />
        </div>)

    if (step === "call")
        return (<Call {...{setStep}}></Call>);
    if (step === "map")
        return (<Map {...{setStep}}></Map>);
    if (step === "message")
        return (<Message {...{setStep}}></Message>);

    return (<Desktop {...{setStep}}></Desktop>);
}

export const Smartphone = () => {
    return (<div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
            // backgroundImage: `url(${require("./smartphone.png").default})`
        }}
    >
        <Content></Content>
    </div>)
}