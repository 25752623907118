import Cookies from "js-cookie";
import {useState} from "react";
import {useInterval} from "./Hooks";
import {pushClue, pushStep} from "./server/toApi";

// todo obfuscate clues
const clues = {
    'start': [
        "Google Maps te demande une ADRESSE  ",
    ],
    'front': [
        "As-tu vraiment lu les messages d’Annabelle ? Elle te dit quoi faire... Combine les manchettes... et souviens-toi que la prochaine expo porte sur les « sigles & acronymes » (Mais le pire, c’est que la réponse est dans chaque image)",
    ],
    'galerie': [
        "As-tu vraiment lu les messages d’Annabelle ? Elle te dit quoi faire… Oncle Sam pointe sur le bon tableau...",
    ],
  /*  'oncle': [
        "Pour accéder à l'ordinateur, sélectionnez les 5 lettres grecques qui sont visibles sur le contrat et les avenants.",
    ],*/
    'mac1': [
        "Trouve la clause contractuelle la plus pertinente pour la situation d’Annabelle",
    ],
    'mac2': [
        "« le triquet piegeur » = anagramme de « quitter ce guêpier » ; Sortie, Exclusion, Dissolution ; quelle voie de droit lui conseilles-tu ?",
    ],
    // 'print': [
    //     "Pour ouvrir la porte du bureau de l'associé, sélectionnez les symboles que vous trouverez sur le portrait de droite en survolant les yeux avec la souris.",
    // ],
    'associate': [
        "Il y a quatre pouces verts. On ne dissout pas pour des cas bagatelle.",
    ],
    'calc': [
        "As-tu pensé à rajouter les dommages et intérêts qui sont dus par les autres associés ? Les voyages privés et les rabais excessifs consentis aux amis doivent-ils vraiment être à la charge de la société ?",
    ],
    'locker': [
        "Lis le code barre : il te dit tout (sauf la base légale...)",
    ],
}

const stepsOrder = Object.keys(clues);


export const addClueStep = (step) => {
    // console.log("addClueStep", step);
    const clues = JSON.parse(Cookies.get("gameStep") || null);
    if (clues) {
        if (!clues.includes(step)) {
            clues.push(step);
            Cookies.set("gameStep", JSON.stringify(clues));
            pushStep(step).then((res) => {});
        }
    } else {
        Cookies.set("gameStep", JSON.stringify([step]));
    }
}

function getLastStepName(steps) {
    if (!steps)
        return "start";

    for (let i = stepsOrder.length - 1; i >= 0; i--) {
        if (steps.includes(stepsOrder[i]))
            return stepsOrder[i];
    }
    console.error("getLastStepName", steps)
    return "start";
}

function getCluesCount(cluesLevel) {
    let count = 0;
    if (cluesLevel) {
        Object.keys(cluesLevel).forEach((step) => {
            count += cluesLevel[step] + 1;
        })
    }
    return count;
}


const buttonStyle = {
    backgroundColor: 'gray', /* Gray background */
    border: '2px solid #555', /* Dark gray border */
    color: 'white', /* White text */
    padding: '10px 20px', /* Padding */
    textAlign: 'center', /* Center text */
    textDecoration: 'none', /* Remove underline */
    display: 'inline-block', /* Make it an inline element */
    fontSize: '20px', /* Font size */
    cursor: 'pointer', /* Add a pointer cursor on hover */
    borderRadius: '8px', /* Rounded corners */
    marginTop: '12px', /* Top margin */
};

const titleStyle = {
    fontSize: '30px', /* Font size */
    fontWeight: 'bold', /* Bold text */
    marginBottom: '12px', /* Bottom margin */
};
const subTitleStyle = {
    fontSize: '25px', /* Font size */
    fontWeight: 'bold', /* Bold text */
    marginTop: '12px', /* Bottom margin */
};

function useUpdate() {
    const [_, setState] = useState(0);
    return () => setState(i => i + 1);
}

function getHasMoreClueLevel(gameStep, clueLevel) {
    if (clueLevel === null)
        clueLevel = -1;
    return clues[gameStep].length > clueLevel + 1;
}

export const Clues = () => {

    const update = useUpdate();
    const [loadingAsk, setLoadingAsk] = useState(false);
    const [clueError, setClueError] = useState(null);

    useInterval(() => {
        update();
    }, 5000);

    const steps = JSON.parse(Cookies.get("gameStep") || null);
    const gameStep = getLastStepName(steps);
    // console.log("steps", steps)
    // console.log("gameStep", gameStep)


    const cluesLevel = JSON.parse(Cookies.get("cluesLevel") || null) || {};
    // console.log("cluesLevel", cluesLevel)
    const clueLevel = cluesLevel[gameStep] !== undefined ? cluesLevel[gameStep] : null;
    // console.log("clueLevel", clueLevel)

    let clue = null
    if (clueLevel !== null)
        clue = clues[gameStep][clueLevel];

    const hasMoreClueLevel = getHasMoreClueLevel(gameStep, clueLevel);

    const cluesCount = getCluesCount(cluesLevel);
    const penaltyDuration = cluesCount * 30;
    return <div style={{
        fontSize: '20px', /* Font size */
        lineHeight: '1.5', /* Line height */
    }}>
        <div style={titleStyle}>Indices</div>
        <div>Nombre d'indice demandé: {cluesCount} <i>(30 minutes sont ajoutées pour chaque indice demandé au temps de
            jeu de l'équipe)</i></div>
        <div>{cluesCount} X 30min = {penaltyDuration} minute(s)</div>

        {clue && <div>
            <div style={subTitleStyle}>Indice:</div>
            {Array.isArray(clue) ? clue.map((c, i) => <div key={i}>{c}</div>) : <div>{clue}</div>}
        </div>}
        {hasMoreClueLevel && <button
            disabled={loadingAsk}
            onClick={() => {
                setClueError(null);

                const cluesLevel = JSON.parse(Cookies.get("cluesLevel") || null) || {}
                let clueLevel = null;

                if (cluesLevel[gameStep] !== undefined) {
                    clueLevel = cluesLevel[gameStep];
                    const hasMore = getHasMoreClueLevel(gameStep, clueLevel);
                    if (!hasMore) {
                        console.error("askClue hasMore", gameStep, clueLevel)
                        return;
                    }
                    cluesLevel[gameStep] = clueLevel + 1;
                } else
                    cluesLevel[gameStep] = 0;

                setLoadingAsk(true);
                const clueName = gameStep + "_" + cluesLevel[gameStep];
                pushClue(clueName).then((res) => {
                    setLoadingAsk(false)
                    if (res.error) {
                        console.error("pushClue error", res.error)
                        setClueError(res.error);
                        return;
                    }

                    Cookies.set("cluesLevel", JSON.stringify(cluesLevel));
                    setClueError(null);
                    update();
                });
            }}
            style={buttonStyle}
        >
            {loadingAsk ? "Chargement..." : "Demander un indice"}
        </button>}
        {clueError && <div style={{color: "red"}}>{clueError}</div>}
    </div>
}


