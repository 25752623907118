import {Click2d} from "../../Click2d";
import {addDocuments} from "../../documents";

export const imgStackStyle = {
    maxHeight: '100vh',
    maxWidth: '100vw',
    position: "absolute"
};

const width = 961;
const height = 422;

export const Avenant2 = () => {
    return (<div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
        }}
    >
        <div
            style={{
                minHeight: height+'px',
                minWidth: width+'px',
                position: 'relative',
            }}
        >
        <img
            style={{
                height:  height+'px',
                width: width+'px',
            }}
            src={require("./avenant2.png").default}/>
        <Click2d
            style={{
                left: '248px',
                top: '45px',
                width: '282px',
                height: '277px',
            }}
            onClick={() => {
                // console.log("click")
                addDocuments(["avenant2"])
            }}
        ></Click2d>
        </div>
    </div>)
}