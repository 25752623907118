import './App.css';
import {
    Canvas,
} from "@react-three/fiber";
// import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls";
import React, {Suspense, useEffect} from "react";
import PersoCam from "./PersoCam";
import {event} from "./util";
import {
    RecoilRoot,
    atom,
    useRecoilState,
    useRecoilBridgeAcrossReactRoots_UNSTABLE,
} from 'recoil';
import Scene from "./Scene";
import {Page2d} from "./Page2d";
import Chrono, {gameStartedKey} from "./Chrono";
import BackPack, {backPackItemsKey, hasItem, useItem} from "./BackPack";
import Toast, {setToast} from "./Toast";
import Cookies from "js-cookie";
import {initCState, initItems, sound} from "./config";
import {cStateKey, useCState} from "./CState";
import {useInterval} from "./Hooks";
import {answeredKey, garageAnsweredKey, oncleEmailAnsweredKey} from "./scene1/2d/smartphone/Smartphone";
import {factsUnlockedKey} from "./scene4/Calc/Calc";
import {lockerUnlockedKey} from "./scene5/Locker/Locker";
import {Classeur} from "./Classeur";
import {Helmet} from "react-helmet";

export const gyroEnabledState = atom({
    key: 'gyroEnabled',
    default: true,
});
export const gyroAvailableState = atom({
    key: 'gyroAvailable',
    default: false,
});

function Root() {
    const [gyroEnabled, setGyroEnabled] = useRecoilState(gyroEnabledState);
    const [gyroAvailable, setGyroAvailable] = useRecoilState(gyroAvailableState);
    const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE();
    const [answered, _1] = useCState(answeredKey);
    const [gameStarted, _2] = useCState(gameStartedKey);
    const [calcSuccess, _3] = useCState(factsUnlockedKey);
    const [oncleEmailAnswer, _4] = useCState(oncleEmailAnsweredKey);

    const [lockerSuccess, _5] = useCState(lockerUnlockedKey);
    const [garageAnswered, _6] = useCState(garageAnsweredKey);

    let [itemList, setItems] = useCState(backPackItemsKey);


    useInterval(() => {
        // console.log("gameStarted", gameStarted, answered)
        // console.log("calcSuccess", calcSuccess, oncleEmailAnswer)
        // console.log("lockerSuccess", lockerSuccess, garageAnswered)
        if ((gameStarted && !answered)
            || (calcSuccess && !oncleEmailAnswer)
            || (lockerSuccess && !garageAnswered)
        ) {
            setToast("DRING...");
            if (sound) {
                let audio = new Audio(require("./smartphone.mp3").default);
                audio.volume = 0.1;
                audio.play();
            }
        }
        // }, 5000);
    }, 10000);
    //
    useEffect(() => {
        if (initCState) {
            // console.log("Change initCState", initCState)
            Cookies.set(cStateKey, JSON.stringify(initCState));
        }
        if (!itemList)
            itemList = [];
        if (!itemList.includes("Folder")) {
            itemList.push("Folder");
        }
        setItems([...itemList]);
    }, []);

    return (<div className="App">

        <Helmet>
            <title>Enquête UNIGE</title>
            <link rel="icon" href="/favicon.ico"/>
        </Helmet>
        <Canvas className="fiber">
            <RecoilBridge>
                <Suspense fallback={null}>
                    <Scene/>
                </Suspense>
                <PersoCam/>
                <ambientLight intensity={1}/>
            </RecoilBridge>
        </Canvas>
        {!gyroEnabled && gyroAvailable &&
            <img src={require("./gyro.png").default} className={"gyroEnabler"}
                 onClick={() => setGyroEnabled(true)}
            />}
        <Page2d/>
        <Chrono/>
        <BackPack/>
        <Toast/>
    </div>)
}

function App() {

    // get url param "classeur"
    const urlParams = new URLSearchParams(window.location.search);
    const classeur = urlParams.get('classeur');
    if (classeur) {
        return (<Classeur></Classeur>)
    }

    return (
        <RecoilRoot>
            <Root/>
        </RecoilRoot>
    );
}

export default App;
