import {useLoader} from "@react-three/fiber";
import * as THREE from "three";
import {TextureLoader} from "three";
import {ClickZone, To2d, ToScene} from "../../../Zone";
import {useEffect, useState} from "react";
import {addItem, hasItem, useItem} from "../../../BackPack";

export function Cuisine() {
    // console.log(" :", ref)
    const colorMap = useLoader(TextureLoader, require('./cuisine.jpg').default);
    const smartphone = useLoader(TextureLoader, require('./smartphone.png').default);

    const [isSmartPhone, setIsSmartPhone] = useItem("Smartphone");

    return <group rotation={[0, 1.6 * Math.PI, 0]}>

        <mesh rotation={[0, Math.PI * 1, 0]}>
            <sphereGeometry args={[500, 60, 40]}/>
            <meshStandardMaterial map={colorMap} side={THREE.DoubleSide}/>
        </mesh>
        <ToScene name={"Salon"} y={-0.2} z={33}/>
        <ToScene name={"Hall"} y={-0.2} z={117}/>
        <To2d name={"Frigo"} y={0} z={288}/>

        {!isSmartPhone && <mesh rotation={[0, Math.PI * 1, 0]}>
            <sphereGeometry args={[500, 60, 40]}/>
            <meshStandardMaterial map={smartphone} transparent='true' side={THREE.DoubleSide}/>
        </mesh>}
        {!isSmartPhone && <ClickZone callback={() => {
            setIsSmartPhone(true);
        }} y={-1.3} z={50}/>}
    </group>
}

