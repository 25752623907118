import {useEffect, useRef, useState} from "react";
import {showSquare} from "../../config";
import {useCState} from "../../CState";
import {useSetRecoilState} from "recoil";
import {page2dState} from "../../Page2d";
import {sceneKey, screenState} from "../../Scene";


export const oncleUnlockedKey = "oncleUnlockedKey";

function getBorderColor(unlocked, error) {
    if (unlocked)
        return "green"
    if (error)
        return "red"
    return "whitesmoke";
}

const letters = [
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
    ['Z', 'X', 'C', 'V', 'B', 'N', 'M'],
]

export const PorteOncle = () => {

    const [code, setCode] = useState("")
    const [error, setError] = useState(false)
    const [unlocked, setUnlocked] = useCState(oncleUnlockedKey);
    const setPage2d = useSetRecoilState(page2dState);
    const [_s,setScreen] = useCState(sceneKey);

    const line1 = []
    letters[0].forEach((letter, index) => {
      line1.push(
        <div
            key={index}
            style={{
                cursor: 'pointer',
                backgroundColor: (showSquare ? "rgba(255, 255, 0, 0.48)" : "transparent"),
                width: '53px',
                height: '67px'
            }}
            onClick={() => {
                if (code.length < 3)
                    setCode(code + letter)
            }}
        >
            {showSquare ? letter : ""}
        </div>)
    })
    const line2 = []
    letters[1].forEach((letter, index) => {
        line2.push(
            <div
                key={index}
                style={{
                    cursor: 'pointer',
                    backgroundColor: (showSquare ? "rgba(255, 255, 0, 0.48)" : "transparent"),
                    width: '53px',
                    height: '67px'
                }}
                onClick={() => {
                    if (code.length < 3)
                        setCode(code + letter)
                }}
            >
                {showSquare ? letter : ""}
            </div>)
    })

    const line3 = []
    letters[2].forEach((letter, index) => {
        line3.push(
            <div
                key={index}
                style={{
                    cursor: 'pointer',
                    backgroundColor: (showSquare ? "rgba(255, 255, 0, 0.48)" : "transparent"),
                    width: '53px',
                    height: '67px'
                }}
                onClick={() => {
                    if (code.length < 3)
                        setCode(code + letter)
                }}
            >
                {showSquare ? letter : ""}
            </div>)
    });


    return (<div
        style={{
            display: 'flex',
            position: "relative",
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
        }}
    >
        <div
            style={{
                backgroundColor: '#232326',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
            }}
        >
            <div
                style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    backgroundColor: (showSquare ? "rgba(255, 255, 0, 0.48)" : "transparent"),
                    marginTop: '383px',
                    marginLeft: '464px',
                    width: '136px',
                    height: '59px'
                }}
                onClick={() => {
                    if (code.toLowerCase() === "dis") {
                        setUnlocked(true);
                        setTimeout(() => {
                            setScreen("Oncle");
                            setPage2d(null);
                        }, 1000);
                    } else {
                        setError(true);
                    }
                }}
            ></div>
            <input type="text"
                   style={{
                       // width: '100%'
                       alignItems: 'center',
                       backgroundColor: '#232326',
                       border: "whitesmoke solid 1px",
                       borderColor: getBorderColor(unlocked, error),
                       borderRadius: "25px",
                       color: "whitesmoke",
                       fontSize: "35px",
                       padding: "10px",
                       margin: "25px",
                       textAlign: 'center',
                       fontFamily: "texas tango",
                   }}
                   placeholder={"#***"}
                   onChange={(e) => {
                       let new_code = e.target.value.toUpperCase().replace("#", "");
                       if (new_code.length > 3)
                            return;
                       setCode(new_code);
                       setError(false);
                       setUnlocked(false);
                   }}
                   value={code !== "" ? "#"+code : ""}
            />
            <div
                style={{
                    display: 'flex',
                    position: 'absolute',
                    top: '128px',
                    left: '8px',
                    gap: '9px'
                }}
            >
                {line1}
            </div>
            <div
                style={{
                    display: 'flex',
                    position: 'absolute',
                    top: '212px',
                    left: '36px',
                    gap: '9px'
                }}
            >
                {line2}
            </div>
            <div
                style={{
                    display: 'flex',
                    position: 'absolute',
                    top: '297px',
                    left: '99px',
                    gap: '9px'
                }}
            >
                {line3}
            </div>

            <div
                style={{
                    position: 'absolute',
                    top: '296px',
                    left: '556px',
                    cursor: 'pointer',
                    backgroundColor: (showSquare ? "rgba(255, 255, 0, 0.48)" : "transparent"),
                    width: '53px',
                    height: '67px'
                }}
                onClick={() => {
                    if (code.length > 0)
                        setCode(code.slice(0, -1))
                }}
            >
                {showSquare ? "del" :""}
            </div>

            <img
                src={require("./keyboard.png").default}
                // style={{
                //     position: "absolute",
                //     width: '600px',
                //     height: '400px'
                // }}
            />
        </div>
    </div>)
}