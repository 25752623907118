export const imgStackStyle = {
    maxHeight: '100vh',
    maxWidth: '100vw',
    position: "absolute"
};


export const Puzzle = () => {


    return (<div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            position: 'absolute',
            top: '0',
            left: '0'
        }}

    >
        <iframe
            style={{height:'600px',width:'1000px', border:'none'}}
            src="/puzzle.html">
            Loading...
        </iframe>

    </div>)
}