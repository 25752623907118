import {useCState} from "../../CState";
import {useEffect, useState} from "react";
import {Click2d} from "../../Click2d";
import {completeResponse} from "../../config";
import {arrayEquals, log} from "../../util";
import {Slot} from "./slot";
import {addDocuments} from "../../documents";
import {addClueStep} from "../../clues";

const macStepKey = "macStep";

export const welcomeStyle = {
    width: '1112px',
    height: '658px',
    position: 'relative',
    backgroundImage: `url(${require("./welcome.png").default})`
};

const bgs = {
    "spam1": require("./spam1.png").default,
    "spam2": require("./spam2.png").default,
    "spam3": require("./spam3.png").default,
    "avenant5": require("./avenant5.png").default,
}

export const Emails = ({setStep}) => {
    const [email, setEmail] = useState("spam1");
    let left = '110px';
    let width = '260px';
    return (<div
        style={{
            ...welcomeStyle,
            backgroundImage: `url(${bgs[email]})`
        }}>
        <Click2d
            style={{
                left: '104px',
                top: '30px',
                width: '20px',
                height: '20px',
            }}
            onClick={() => setStep("welcome")}
        ></Click2d>
        <Click2d
            style={{
                left,
                top: '115px',
                width,
                height: '80px',
            }}
            onClick={() => setEmail("spam1")}
        ></Click2d>
        <Click2d
            style={{
                left,
                top: '200px',
                width,
                height: '80px',
            }}
            onClick={() => setEmail("spam2")}
        ></Click2d>
        <Click2d
            style={{
                left,
                top: '285px',
                width,
                height: '80px',
            }}
            onClick={() => setEmail("spam3")}
        ></Click2d>
        <Click2d
            style={{
                left,
                top: '380px',
                width,
                height: '80px',
            }}
            onClick={() => setEmail("avenant5")}
        ></Click2d>
        {email === 'avenant5' && <Click2d
            style={{
                left: '420px',
                top: '155px',
                width: '146px',
                height: '40px',
            }}
            onClick={() => {
                addDocuments(["avenant5"])
            }}
        ></Click2d>}
    </div>)
}
export const Wordle = ({setStep}) => {
    // console.log("Wordle");
    return (<div
        style={{
            ...welcomeStyle,
            backgroundImage: `url(${require("./wordle.png").default})`
        }}>
        <Click2d
            style={{
                left: '165px',
                top: '30px',
                width: '20px',
                height: '20px',
            }}
            onClick={() => setStep("welcome")}
        ></Click2d>
        <iframe
            style={{
                position: 'absolute',
                left: '151px',
                top: '55px',
                height: '523px',
                width: '803px'
            }}
            src="/wordle/index.html">
            Loading...
        </iframe>
    </div>)
}


const Welcome = ({setStep}) => {


    useEffect(() => {
        addClueStep("mac2")
    }, [])

    return (<div
        style={{...welcomeStyle}}>

        <Click2d
            style={{
                left: '120px',
                top: '80px',
                width: '180px',
                height: '140px',
            }}
            onClick={() => setStep("wordle")}
        ></Click2d>
        <Click2d
            style={{
                left: '320px',
                top: '80px',
                width: '150px',
                height: '140px',
            }}
            onClick={() => setStep("emails")}
        ></Click2d>
        <Click2d
            style={{
                left: '480px',
                top: '80px',
                width: '150px',
                height: '140px',
            }}
            onClick={() => setStep("slot")}
        ></Click2d>
        <img
            style={{
                position: 'absolute',
                left: '698px',
                top: '70px',
                width: '300px',
                height: '70px',
                cursor: 'pointer'
            }}
            onClick={() => setStep("emails")}
            src={require("./notification.png").default}/>
    </div>)
}


const letters = ["Α", "Β", "Γ", "Δ", "Ε", "Ζ", "Η", "Θ", "Ι", "Κ", "Λ", "Μ", "Ν", "Ξ", "Ο", "Π", "Ρ", "Σ", "Τ", "Υ", "Φ", "Χ", "Ψ", "Ω"];

const Password = ({setStep}) => {

    const [enableds, setEnableds] = useState(completeResponse ? ["Ω", "Δ", "Π", "Σ"] : []);

    // 01 contrat Θ
    // 02 1 Ω
    // 03 2 Δ
    // 04 3 Π
    // 05 4 Σ
    // 06 5 Φ

    useEffect(() => {
            enableds.sort()
            // console.log("enableds", enableds.join(""))
            if (enableds.join("") === "ΔΘΠΣΩ") //ΩΔΠΣΘ sorted
                setStep("password2")
        },
        [enableds]);

    const rows = [];
    letters.forEach((letter, index) => {
        rows.push(<div style={{
            fontSize: '20px',
            backgroundColor: enableds.includes(letter) ? '#c1b8e7' : 'white',
            cursor: 'pointer',
            borderRadius: '55px',
            display: 'inline-block',
            padding: '9px',
            height: '40px',
            width: '40px',
            textAlign: 'center'
        }}
                       onClick={() => {
                           if (enableds.includes(letter))
                               setEnableds(enableds.filter(e => e !== letter))
                           else {
                               let newletters = [...enableds, letter];
                               newletters.sort()
                               setEnableds(newletters)
                           }
                       }
                       }>
            {letter}
        </div>);
    })

    return (<div
        style={{
            ...welcomeStyle,
            backgroundImage: `url(${require("./lock.png").default})`
        }}>
        <div
            style={{
                position: 'absolute',
                left: '300px',
                top: '355px',
                width: '500px',
                height: '80px',
                display: 'flex',
                gap: '5px',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
        >
            {rows}
        </div>
    </div>)
}
const Password2 = ({setStep}) => {
    const chesses = "♔ ♕ ♖ ♗ ♘ ♙ ♚ ♛ ♜ ♝ ♞ ♟".split(" ");
    const planets = "☉ ☿ ♀ ♁ ♃ ♄ ♅ ♆ ♇".split(" ");

    const [left, setLeft] = useState(completeResponse ? "♘" : "");
    const [right, setRight] = useState("");


    useEffect(() => {
            // console.log("left right", left, right)
            if (left === "♘" && right === "♅")
                setStep("desktop")
        },
        [left, right]);

    const leftRows = chesses.map((letter) => {
        return (<div style={{
            fontSize: '20px',
            cursor: 'pointer',
            borderRadius: '55px',
            display: 'inline-block',
            padding: '9px',
            height: '40px',
            width: '40px',
            textAlign: 'center',
            color: (left === letter) ? '#ffffff' : '#000c8c',
            backgroundColor: left === letter ? '#000c8c' : '#ffffff',
            fontWeight: 'bold',

        }}
                     onClick={() => {
                         if (left === letter)
                             setLeft("")
                         else
                             setLeft(letter)
                     }
                     }>
            {letter}
        </div>);
    })

    const rightRows = planets.map((letter) => {
            return (<div style={{
                fontSize: '20px',
                cursor: 'pointer',
                borderRadius: '55px',
                display: 'inline-block',
                padding: '9px',
                height: '40px',
                width: '40px',
                textAlign: 'center',
                color: (right === letter) ? '#ffffff' : '#8c0000',
                backgroundColor: right === letter ? '#8c0000' : '#ffffff',
            }}
                         onClick={() => {
                             if (right === letter)
                                 setRight("")
                             else
                                 setRight(letter)
                         }
                         }>
                {letter}
            </div>);
        }
    )


    useEffect(() => {
        addClueStep("mac1")
    }, [])


    return (<div
        style={{
            ...welcomeStyle,
            backgroundImage: `url(${require("./lock.png").default})`
        }}>
            <div
                style={{
                    position: 'absolute',
                    left: '0px',
                    top: '320px',
                    width: '100%',
                    height: '80px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '30px',
                    fontWeight: 'bold',
                    color: '#ffffff',
                    textShadow: '0 0 10px #000000'}
                }
            >
                Que deviens tu Annabelle ?
            </div>
            <div
                style={{
                    position: 'absolute',
                    left: '250px',
                    top: '385px',
                    width: '300px',
                    height: '80px',
                    display: 'flex',
                    gap: '5px',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                }}
            >
                {leftRows}
            </div>
            <div
                style={{
                    position: 'absolute',
                    left: '550px',
                    top: '385px',
                    width: '300px',
                    height: '80px',
                    display: 'flex',
                    gap: '5px',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    color: '#8c0000',
                }}
            >
                {rightRows}
            </div>
    </div>)
}

const Content = () => {

    const [step, setStep] = useCState(macStepKey, "password");
    // const [step, setStep] = useCState(macStepKey, "password2");

    // console.log("step", step)

    if (step === "password")
        return (<Password {...{setStep}}></Password>);

    if (step === "password2")
        return (<Password2 {...{setStep}}></Password2>);

    if (step === "slot")
        return (<Slot {...{setStep}}></Slot>);

    if (step === "emails")
        return (<Emails {...{setStep}}></Emails>);

    if (step === "wordle")
        return (<Wordle {...{setStep}}></Wordle>);

    return (<Welcome {...{setStep}}></Welcome>);
}

export const Mac = () => {
    return (<div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
            position: 'absolute',
            left: '0',
            top: '0'
        }}
    >
        <Content></Content>
    </div>)
}