import {useEffect, useState} from "react";
import {completeResponse} from "../../config";
import {useCState} from "../../CState";
import {Click2d} from "../../Click2d";
import {arrayEquals} from "../../util";
import {welcomeStyle} from "./Mac";
import {addClueStep} from "../../clues";

const slotTexts = [["Dissolution", "Exclusion", "Sortie", "Résiliation", "Révocation", "Invalidation", "Annulation", "Nullité"],
    ["Décès", "Curatelle", "Violation de la loi", "But atteint", "Faillite", "Juste motif", "Erreur essentielle", "Droit contractuel"],
    ["Chaque associé*e", "Associées (ensemble)", "Associé*e gérant*e (seul*e)", "Associées gérant*es (ensemble)", "Mandataire général", "Tribunal", "Administration", "Créancier"]
]

const slotAnswer = [0, 5, 5];

function next(number, array) {
    if (number === array.length - 1)
        return 0;
    return number + 1;
}

export const slotAnswerKey = "slotAnswer";
export const Slot = ({setStep}) => {
    const [selecteds, setSelecteds] = useState(completeResponse ? [0, 5, 4] : [2, 2, 2]);
    const [transition, setTransition] = useState([false, false, false]);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useCState(slotAnswerKey, false);
    useEffect(() => {
        // console.log("useEffect error", error)
        if (error === true)
            setTimeout(() => {
                    setError(false);
                }
                , 1000)
    }, [error])

    let widthBox = '162px';
    const heightBox = '108px';

    let backgroundColor = 'rgb(0 35 48)';
    if (error)
        backgroundColor = '#6b0000';
    if (success)
        backgroundColor = '#006b00';


    const boxStyle = {
        position: 'absolute',
        width: widthBox,
        height: heightBox,
        maxWidth: widthBox,
        maxHeight: heightBox,
        backgroundColor: backgroundColor,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        transition: "background-color 1s ease"
    }
    const textStyle = {
        position: 'absolute',
        fontSize: '20px',
        fontWeight: 'bold',
        color: 'white',
        textAlign: 'center',
        width: widthBox,
        height: heightBox,
        minWidth: widthBox,
        minHeight: heightBox,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        cursor: 'pointer',
        borderTop: '2px dashed white',
        boxSizing: 'border-box',
    }

    const lefts = [
        <div
            id={"slot0-1-" + selecteds[0]}
            style={{
                ...textStyle,
                top: transition[0] ? heightBox : "-1px",
                transition: transition[0] ? "top 0.5s ease-in-out" : "none",
            }}
        >
            {slotTexts[0][selecteds[0]]}
        </div>,

        <div
            id={"slot0-2-" + selecteds[0]}
            style={{
                ...textStyle,
                top: transition[0] ? "-1px" : "-" + heightBox,
                transition: transition[0] ? "top 0.5s ease-in-out" : "none",
            }}
            onTransitionEnd={() => {
                setTransition([false, transition[1], transition[2]])
                // console.log("transition end", selecteds[0], ">", next(selecteds[0], slotTexts[0]))
                setSelecteds([next(selecteds[0], slotTexts[0]), selecteds[1], selecteds[2]])
            }}

        >
            {slotTexts[0][next(selecteds[0], slotTexts[0])]}
        </div>
    ]

    const middles = [
        <div
            id={"slot1-1-" + selecteds[1]}
            style={{
                ...textStyle,
                top: transition[1] ? heightBox : "-1px",
                transition: transition[1] ? "top 0.5s ease-in-out" : "none",
            }}
        >
            {slotTexts[1][selecteds[1]]}
        </div>,
        <div
            id={"slot1-2-" + selecteds[1]}
            style={{
                ...textStyle,
                top: transition[1] ? "-1px" : "-" + heightBox,
                transition: transition[1] ? "top 0.5s ease-in-out" : "none",
            }}
            onTransitionEnd={() => {
                setTransition([transition[0], false, transition[2]])
                setSelecteds([selecteds[0], next(selecteds[1], slotTexts[1]), selecteds[2]])
            }}
        >
            {slotTexts[1][next(selecteds[1], slotTexts[1])]}
        </div>
    ]

    const rights = [
        <div
            id={"slot2-1-" + selecteds[2]}
            style={{
                ...textStyle,
                top: transition[2] ? heightBox : "-1px",
                transition: transition[2] ? "top 0.5s ease-in-out" : "none",
            }}
        >
            {slotTexts[2][selecteds[2]]}
        </div>,
        <div
            id={"slot2-2-" + selecteds[2]}
            style={{
                ...textStyle,
                top: transition[2] ? "-1px" : "-" + heightBox,
                transition: transition[2] ? "top 0.5s ease-in-out" : "none",
            }}
            onTransitionEnd={() => {
                setTransition([transition[0], transition[1], false])
                setSelecteds([selecteds[0], selecteds[1], next(selecteds[2], slotTexts[2])])
            }}
        >
            {slotTexts[2][next(selecteds[2], slotTexts[2])]}
        </div>
    ]


    return (<div
        style={{
            ...welcomeStyle,
            backgroundImage: `url(${require("./slot.png").default})`
        }}>

        <Click2d
            style={{
                left: '165px',
                top: '30px',
                width: '20px',
                height: '20px',
            }}
            onClick={() => setStep("welcome")}
        ></Click2d>

        <Click2d
            style={{
                left: '880px',
                top: '100px',
                width: '80px',
                height: '200px',
            }}
            onClick={() => {
                if (arrayEquals(selecteds, slotAnswer)) {
                    setSuccess(true)
                    addClueStep("print")
                } else
                    setError(true)
            }}
        ></Click2d>
        <div
            style={{
                ...boxStyle,
                left: '264px',
                top: '163px',
            }}
            onClick={() => {
                setTransition([true, transition[1], transition[2]])
            }}
        >
            {lefts}
        </div>
        <div
            style={{
                ...boxStyle,
                left: '455px',
                top: '163px',
            }}
            onClick={() => {
                setTransition([transition[0], true, transition[2]])
            }}
        >
            {middles}

        </div>
        <div
            style={{
                ...boxStyle,
                left: '650px',
                top: '163px',
            }}
            onClick={() => {
                setTransition([transition[0], transition[1], true])
            }}
        >
            {rights}
        </div>
        <img src={require("./print.png").default}
             style={{
                 position: 'absolute',
                 top: '270px',
                 left: '285px',
                 width: '501px',
                 height: '96px',
                 transform: success ? "scale(1)" : "scale(0)",
                 transition: "transform 1s ease-in-out",
             }}>

        </img>
    </div>)
}
