import Cookies from 'js-cookie';
import {addItem, hasItem} from "./BackPack";
import {useEffect, useRef, useState} from "react";

export const cStateKey = "cState";

const setters = {};


export function getCValue(fieldName, defvalue) {
    let jsonStr = Cookies.get(cStateKey);
    // console.log("jsonStr: "+jsonStr);
    if (!jsonStr)
        return defvalue;
    const cState = JSON.parse(jsonStr);
    let valueElement = cState[fieldName];
    if (valueElement === undefined)
        return defvalue;
    return valueElement;
}

export const useCState = (fieldName, defvalue) => {
    const idRef = useRef(Math.random() + "");
    const [value, setValue] = useState(getCValue(fieldName,defvalue));
    if (!setters[fieldName])
        setters[fieldName] = {}
    setters[fieldName][idRef.current] = setValue;

    useEffect(() => {
        const valueElement = getCValue(fieldName);
        if (valueElement !== undefined)
            setValue(valueElement);
        return () => {
            delete setters[fieldName][idRef.current];
        }
    }, []);

    return [value, (value) => {
        let jsonStr = Cookies.get(cStateKey);
        let cState = {};
        if (jsonStr)
            cState = JSON.parse(jsonStr);
        cState[fieldName] = value;
        Cookies.set(cStateKey, JSON.stringify(cState));
        // console.log("currentId: "+idRef.current);
        try {
            Object.entries(setters[fieldName]).forEach(([id, sett]) => {
                // console.log("set fieldName: " + fieldName + ", id: " + id + ", value: " + value)
                sett(value);
            });
        } catch (e) {
            // console.log("Error setters: ", setters)
            console.error(e)
        }
    }];

}