import {showSquare} from "./config";

export const Click2d = ({style, ...params}) => {
    return  <div
        style={{
            cursor: 'pointer',
            position: 'absolute',
            ...style,
            backgroundColor: (showSquare ? "rgba(255, 255, 0, 0.48)":"transparent")
        }}
        {...{...params}}
    ></div>
}