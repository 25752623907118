import {useLoader} from "@react-three/fiber";
import * as THREE from "three";
import {TextureLoader} from "three";
import {BigTo2d, ClickDocs, ClickZone, To2d, ToScene} from "../../Zone";
import {useCState} from "../../CState";
import {lockerUnlockedKey} from "../Locker/Locker";
import {hasItem, useItem} from "../../BackPack";
import {garageKey} from "../RedBox/RedBox";
import {setToast} from "../../Toast";
import {addDocument, addDocuments} from "../../documents";
import {useSetRecoilState} from "recoil";
import {page2dState} from "../../Page2d";
import {factsUnlockedKey} from "../../scene4/Calc/Calc";
import {useEffect} from "react";
import {addClueStep} from "../../clues";


export const lockerAvailableKey = "lockerAvailableKey";

export function Associate() {
    // console.log(" :", ref)
    const colorMap = useLoader(TextureLoader, require('./associate.png').default);
    colorMap.wrapS = THREE.RepeatWrapping;
    colorMap.repeat.x = -1


    const lockerOpen = useLoader(TextureLoader, require('./lockerOpen.png').default);
    lockerOpen.wrapS = THREE.RepeatWrapping;
    lockerOpen.repeat.x = -1


    const lockerAvailable = useLoader(TextureLoader, require('./lockerAvailable.png').default);
    lockerAvailable.wrapS = THREE.RepeatWrapping;
    lockerAvailable.repeat.x = -1

    let [unlocked, _] = useCState(lockerUnlockedKey);
    let [isLockerAvailable, _2] = useCState(lockerAvailableKey);
    const [hasGarageKey, _k] = useItem(garageKey);
    const [factUnlocked, setUnlocked] = useCState(factsUnlockedKey);
    // console.log("hasGarageKey", hasGarageKey)

    // unlocked = true;
    // isLockerAvailable = true;

    // console.log("unlocked", unlocked)
    // console.log("isLockerAvailable", isLockerAvailable)


    useEffect(() => {
        addClueStep("associate")
    },[])

    return <group>

        <mesh rotation={[0, 0, 0]}>
            <sphereGeometry args={[500, 60, 40]}/>
            <meshStandardMaterial map={colorMap} side={THREE.DoubleSide}/>
        </mesh>

        {unlocked && <mesh rotation={[0, 0, 0]}>
            <sphereGeometry args={[499, 59, 39]}/>
            <meshStandardMaterial map={lockerOpen} transparent='true' side={THREE.DoubleSide}/>
        </mesh>}

        {isLockerAvailable && <mesh rotation={[0, 0, 0]}>
            <sphereGeometry args={[497, 57, 37]}/>
            <meshStandardMaterial map={lockerAvailable} transparent='true' side={THREE.DoubleSide}/>
        </mesh>}

        {!unlocked && <ToScene name={"Oncle"} y={-0.2} z={171}/>}
        {unlocked && <ClickZone y={-0.2} z={171}
                                callback={() => {
                                    setToast("Je ne peux pas sortir par là, les securitas vont arriver !");
                                }}
        />}

        {!hasGarageKey && <ClickZone y={-0.2} z={238}
                                     callback={() => {
                                         setToast("C'est fermé !");
                                     }}
        />}
        {hasGarageKey && <ToScene name={"Remise"} y={-0.2} z={238}/>}


        {!factUnlocked && <To2d name={"Calc"} y={-1.2} z={150}/>}
        {factUnlocked && <To2d name={"BankBox"} y={-1.2} z={150}/>}
        <To2d name={"Velo"} y={-0.2} z={155}/>
        <To2d name={"Larry"} y={0} z={5}/>
        {!unlocked && isLockerAvailable && <To2d name={"Locker"} y={-0.7} z={345}/>}
        {unlocked && isLockerAvailable && <To2d name={"RedBox"} y={-0.7} z={345}/>}

        <BigTo2d name={"Rings"} y={-2.6} z={100}/>

        {/*A Armoire a classeur*/}
        <ClickZone y={-0.6} z={210}
                     callback={() => {
                            addDocuments(["04_02"]);
                     }
        }/>


        {/*B Classeur sur l'armoire*/}
        <ClickZone y={-0.4} z={82}
                        callback={() => {
                            addDocuments(["04_03"]);
                        }
        }/>

        {/*C Boite de livre sur l'armoire*/}
        <ClickZone y={-0.4} z={98}
                        callback={() => {
                            addDocuments(["04_05","04_04"
                                // "bilan-notes.pdf"
                            ]);
                        }
        }/>

        <ClickZone y={-0.4} z={68}
                        callback={() => {
                            addDocuments(["04_09", "04_06"]);
                        }
        }/>

        {/*E 3 Livret relié sur la table*/}
        <ClickZone y={-0.5} z={22}
                        callback={() => {
                            addDocuments(["04_07"]);
                        }
        }/>

        {/*F Pile de livre sur la table*/}
        <ClickZone  y={-1} z={315}
                    callback={() => {
                       addDocuments(["04_08"])
                   }}
        />

        {/*G Classeur sur le bureau*/}
        <ClickZone  y={-1.4} z={60}
                    callback={() => {
                        addDocuments(["05_01", "carteTouristique"])
                    }}
        />

    </group>
}