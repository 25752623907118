import {useCState} from "../../../CState";
import {useEffect, useState} from "react";
import {showSquare} from "../../../config";
import {ListEmails} from "./Emails";
import {Click2d} from "../../../Click2d";
import {CloseInfo, closeInfoDesktopKey} from "./CloseInfo";
import {setToast} from "../../../Toast";
import {addDocument} from "../../../documents";

const desktopStepKey = "desktopStep";

const welcomeStyle = {
    width: '767px',
    height: '581px',
    position: 'relative',
    backgroundImage: `url(${require("./desktop.jpg").default})`
};

const Galerie = ({setStep}) => {
    const [closeInfo, setCloseInfo] = useCState(closeInfoDesktopKey);

    return (<div
        style={{...welcomeStyle}}>
        {!closeInfo && <CloseInfo></CloseInfo>}
        <img
            src={require("./galerieIcons.png").default}
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '20px',
                top: '20px',
                width: '640px',
                height: '451px'
            }}
        />
        <Click2d
            style={{
                left: '617px',
                top: '20px',
                width: '40px',
                height: '34px',
            }}
            onClick={() => {
                setStep("welcome");
                setCloseInfo(true);
            }}
        ></Click2d>
        <Click2d
            style={{
                left: '20px',
                top: '100px',
                width: '100px',
                height: '170px',
            }}
            onClick={() => {
                addDocument("articleGalerie");
                setStep("articleBalthazar")
            }}
        ></Click2d>
    </div>)
}

const Cours = ({setStep}) => {

    const [closeInfo, setCloseInfo] = useCState(closeInfoDesktopKey);
    return (<div
        style={{...welcomeStyle}}>
        {!closeInfo && <CloseInfo></CloseInfo>}
        <img
            src={require("./courWindow.png").default}
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '20px',
                top: '20px',
                width: '640px',
                height: '451px'
            }}
        />
        <Click2d
            style={{
                left: '617px',
                top: '20px',
                width: '40px',
                height: '34px',
            }}
            onClick={() => {
                setStep("welcome");
                setCloseInfo(true);
            }}
        ></Click2d>
        <Click2d
            style={{
                left: '20px',
                top: '100px',
                width: '100px',
                height: '170px',
            }}
            onClick={() => {
                window.open("/doc/SS_SNC_Qualification.pdf")
            }}
        ></Click2d>
        <Click2d
            style={{
                left: '140px',
                top: '100px',
                width: '100px',
                height: '170px',
            }}
            onClick={() => {
                window.open("/doc/SS_SNC_Entree sortie et exclusion.pdf")
            }}
        ></Click2d>
        <Click2d
            style={{
                left: '260px',
                top: '100px',
                width: '100px',
                height: '170px',
            }}
            onClick={() => {
                window.open("/doc/SS_SNC_Fin de la societe I.pdf")
            }}
        ></Click2d>
        <Click2d
            style={{
                left: '360px',
                top: '100px',
                width: '100px',
                height: '170px',
            }}
            onClick={() => {
                window.open("/doc/SS_SNC_Fin de la societe II.pdf")
            }}
        ></Click2d>
    </div>)
}

const ArticleBalthazar = ({setStep}) => {

    return (<div
        style={{...welcomeStyle}}>
        <img
            src={require("./pdfWindow.jpg").default}
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '20px',
                top: '20px',
                width: '640px',
                height: '451px'
            }}
        />

        <div
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '73px',
                top: '76px',
                width: '587px',
                height: '395px',
                overflowY: 'scroll',
                overflowX: 'hidden',
            }}
        >
            <img
                src={require("./articleBatlhazar.jpg").default}
                style={{
                    width: '571px',
                }}
            />
        </div>
        <Click2d
            style={{
                left: '617px',
                top: '20px',
                width: '40px',
                height: '34px',
            }}
            onClick={() => setStep("galerie")}
        ></Click2d>

    </div>)

}

const AvisDeces = ({setStep}) => {

    return (<div
        style={{...welcomeStyle}}>
        <img
            src={require("./notesGalerie.jpg").default}
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '20px',
                top: '20px',
                width: '640px',
                height: '451px'
            }}
        />
        <Click2d
            style={{
                left: '617px',
                top: '20px',
                width: '40px',
                height: '34px',
            }}
            onClick={() => setStep("galerie")}
        ></Click2d>

    </div>)

}

const Exercice = ({setStep}) => {

    return (<div
        style={{...welcomeStyle}}>
        <img
            src={require("./exercice.png").default}
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '20px',
                top: '20px',
                width: '640px',
                height: '451px'
            }}
        />
        <Click2d
            style={{
                left: '617px',
                top: '20px',
                width: '40px',
                height: '34px',
            }}
            onClick={() => setStep("cours")}
        ></Click2d>

    </div>)

}

export const siteGaleAvailableKey = "siteGaleAvailableKey";

let clearTimeoutRef = null;

const WebGal = ({setStep}) => {

    const [closeInfo, setCloseInfo] = useCState(closeInfoDesktopKey);
    const [siteGaleAvailable, setSiteGaleAvailable] = useCState(siteGaleAvailableKey);
    const [showPopup, setShowPopup] = useState(false);
    useEffect(() => {
        // console.log("!!!!siteGaleAvailable", siteGaleAvailable)
        if (!siteGaleAvailable) {
            clearTimeoutRef = setTimeout(() => {
                setShowPopup(true);

            },1000)
        }else
            clearTimeoutRef && clearTimeout(clearTimeoutRef);
    });
    return (<div
        style={{...welcomeStyle}}>
        {!closeInfo && <CloseInfo></CloseInfo>}
        {showPopup && <div className="popup-container">
            <div className="popup" onClick={()=> {
                setShowPopup(false);
                setSiteGaleAvailable(true);
            }}>
                Le site internet de la galerie a été ajouté à votre classeur.
                <img style={{
                    width: "3rem",
                    margin: "0.5rem",
                }}
                     src={require("../../../folder.png").default}/>
                <img style={{
                    width: "3rem",
                    margin: "0.5rem",
                }}
                     src={require("./close.png").default}/>
            </div>
        </div>}
        <img
            src={require("./galWebWindow.png").default}
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '30px',
                top: '30px',
                width: '706px',
                height: '490px'
            }}

            onClick={() => {
                setStep("welcome");
                setCloseInfo(true);
            }}
        />
        <iframe style={{
            position: 'absolute',
            left: '31px',
            top: '118px',
            width: '705px',
            height: '401px',
            background: "black",
            color: "white"
        }}
                src="/galerie/index.html"></iframe>
    </div>)
}
const Rdc = ({setStep}) => {
    const [closeInfo, setCloseInfo] = useCState(closeInfoDesktopKey);
    return (<div
        style={{...welcomeStyle}}>
        {!closeInfo && <CloseInfo></CloseInfo>}
        <img
            src={require("./rdcWindow.png").default}
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '30px',
                top: '30px',
                width: '706px',
                height: '490px'
            }}
        />
        <iframe
            style={{
                position: 'absolute',
                left: '31px',
                top: '179px',
                width: '704px',
                height: '339px'
            }}
            frameBorder="0"
            src={"/Rdc.html"}>

        </iframe>
        <Click2d
            style={{
                left: '695px',
                top: '31px',
                width: '40px',
                height: '34px',
            }}
            onClick={() => {
                setStep("welcome");
                setCloseInfo(true);
            }}
        ></Click2d>
    </div>)
}
const Pictures = ({setStep}) => {
    const [closeInfo, setCloseInfo] = useCState(closeInfoDesktopKey);
    return (<div
        style={{...welcomeStyle}}>
        {!closeInfo && <CloseInfo></CloseInfo>}
        <img
            src={require("./pictruresWindow.png").default}
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '30px',
                top: '30px',
                width: '706px',
                height: '490px'
            }}
        />
        <Click2d
            style={{
                left: '695px',
                top: '31px',
                width: '40px',
                height: '34px',
            }}
            onClick={() => {
                setStep("welcome");
                setCloseInfo(true);
            }}
        ></Click2d>
    </div>)
}

const Welcome = ({setStep}) => {
    return (<div
        style={{...welcomeStyle}}>
        <img
            src={require("./folderIcon.png").default}
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '20px',
                top: '20px',
                width: '125px',
                height: '125px'
            }}
            onClick={() => {
                setStep("galerie")
            }}
        />
        <img
            src={require("./CoursIcon.png").default}
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '150px',
                top: '20px',
                width: '125px',
                height: '125px'
            }}
            onClick={() => {
                setStep("cours")
            }}
        />
        <img
            src={require("./emailsIcon.png").default}
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '280px',
                top: '20px',
                width: '125px',
                height: '125px'
            }}
            onClick={() => {
                setStep("listEmails")
            }}
        />
        <img
            src={require("./RdcIcon.png").default}
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '414px',
                top: '20px',
                width: '125px',
                height: '125px'
            }}
            onClick={() => {
                setStep("rdc")
                addDocument("rdc")
            }}
        />
        <img
            src={require("./photosIcon.png").default}
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '540px',
                top: '20px',
                width: '125px',
                height: '125px'
            }}
            onClick={() => {
                setStep("pictures")
            }}
        />
        <img
            src={require("./galWebIcon.png").default}
            style={{
                cursor: "pointer",
                position: 'absolute',
                left: '20px',
                top: '155px',
                width: '125px',
                height: '125px'
            }}
            onClick={() => {
                setStep("webGal")
                addDocument("galerieWeb")
            }}
        />
    </div>)
}

const Password = ({setStep}) => {

    const [password, setPassword] = useState("");

    useEffect(() => {
            // console.log("password", password)
            let fpassword = password.toLowerCase()
            // remove accent
            fpassword = fpassword.replace(/é/g, "e");
            if (fpassword === "universite")
                setStep("desktop")
        },
        [password]);

    return (<div
        style={{
            width: '767px',
            height: '581px',
            position: 'relative',
            backgroundImage: `url(${require("./password.jpg").default})`
        }}>
        <input
            value={password}
            onChange={e => setPassword(e.target.value)}
            type={"password"}
            placeholder={"Mot de passe"}
            style={{
                position: 'absolute',
                left: '277px',
                top: '302px',
                width: '200px',
                height: '30px'
            }}
        />
    </div>)
}

const Content = () => {

    const [step, setStep] = useCState(desktopStepKey, "password");

    // console.log("step", step)

    if (step === "password")
        return (<Password {...{setStep}}></Password>);
    if (step === "galerie")
        return (<Galerie {...{setStep}}></Galerie>);
    if (step === "articleBalthazar")
        return (<ArticleBalthazar {...{setStep}}></ArticleBalthazar>);
    if (step === "avisDeces")
        return (<AvisDeces {...{setStep}}></AvisDeces>);
    if (step === "listEmails")
        return (<ListEmails {...{setStep}}></ListEmails>);
    if (step === "cours")
        return (<Cours {...{setStep}}></Cours>);
    if (step === "exercice")
        return (<Exercice {...{setStep}}></Exercice>);
    if (step === "rdc")
        return (<Rdc {...{setStep}}></Rdc>);
    if (step === "pictures")
        return (<Pictures {...{setStep}}></Pictures>);
    if (step === "webGal")
        return (<WebGal {...{setStep}}></WebGal>);
    return (<Welcome {...{setStep}}></Welcome>);
}



export const Desktop = () => {
    return (<div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
            position: 'absolute',
            left: '0',
            top: '0'
        }}
    >

        <Content></Content>
    </div>)
}