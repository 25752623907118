import {useCState} from "../../../CState";
import moment from "moment";
import {useAudio, useInterval} from "../../../Hooks";
import {useEffect, useState} from "react";
import {showSquare} from "../../../config";

export const Message = ({setStep}) => {

    const [now, setNow] = useState(null);
    const [gameStarted, _] = useCState("gameStarted");

    useInterval(() => {
        setNow(Date.now());
    }, 1000);

    let date;
    if (now) {
        const startedDuration = Date.now() - gameStarted;
        date = new Date();
        date.setHours(18, 0, 0);
        // date.setTime(date.getTime()+startedDuration)
        date.setTime(date.getTime() + startedDuration)
        // console.log("date " + date.getTime())
    }

    return (<div
        style={{
            width: '324px',
            height: '650px',
            position: 'relative',
            backgroundImage: `url(${require("./message.png").default})`
        }}>
        {date && <div style={{
            position: 'absolute',
            top: '25px',
            left: '30px',
            color: '#ffffffe3',
            fontSize: '10px',
            fontFamily: 'arial',
        }}>
            {moment(date).format('HH:mm')}
        </div>}
        <div
            onClick={() => {
                setStep("desktop");
            }}
            style={{
                position: 'absolute',
                bottom: '25px',
                left: '17px',
                width: '290px',
                height: '32px',
                // backgroundColor: 'yellow'
                backgroundColor: showSquare ? "yellow": "transparent",
            }}>
        </div>
    </div>)
}