import {useLoader} from "@react-three/fiber";
import * as THREE from "three";
import {TextureLoader} from "three";
import {ClickZone, To2d, ToScene, Zone} from "../../../Zone";
import {hasItem} from "../../../BackPack";
import {useCState} from "../../../CState";
import {useSetRecoilState} from "recoil";
import {page2dState} from "../../../Page2d";
import {sceneKey, screenState} from "../../../Scene";
import {useEffect} from "react";
import {addClueStep} from "../../../clues";

export const  galerieUnlockedKey = "galerieUnlockedKey";
export function Front() {
    // console.log(" :", ref)
    const colorMap = useLoader(TextureLoader, require('./front.jpg').default);
    const [unlocked, setUnlocked] = useCState(galerieUnlockedKey);
    const [_s,setScreen] = useCState(sceneKey);
    const setPage2d = useSetRecoilState(page2dState);

    useEffect(() => {
        addClueStep("front")
    },[])


    return <group>

        <mesh rotation={[0, 0, -0.15]}>
            <sphereGeometry args={[500, 60, 40]}/>
            <meshStandardMaterial map={colorMap} side={THREE.DoubleSide}/>
        </mesh>
        {/*<ToScene name={"Paint1"} y={-0.4} z={23} />*/}
        <ToScene name={"Hall"} y={-0.4} z={-90} />
        <To2d name={"Acronym"} y={-0.4} z={55}/>
        <To2d name={"FormEtDroit"} y={0.65} z={55}/>
        <To2d name={"Graffiti"} y={-0.6} z={2}/>
        <To2d name={"Cassettes"} y={-0.4} z={-45}/>
        <ClickZone y={-0.4} z={23}
                   callback ={(event) => {
                  if (unlocked)
                    setScreen("Paint1");
                  else
                    setPage2d("PorteGalerie");



              }}></ClickZone>

    </group>
}