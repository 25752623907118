import {atom, useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {screens} from "./Scene";
import {isLand} from "./util";
import {useEffect} from "react";
import {gyroEnabledState} from "./App";
import {Welcome} from "./scene1/2d/Welcome";
import {default2D} from "./config";
import {Frigo} from "./scene1/2d/frigo/Frigo";
import {Smartphone} from "./scene1/2d/smartphone/Smartphone";
import {Desktop} from "./scene1/2d/desktop/Desktop";
import {Acronym} from "./scene2/2d/acronym/Acronym";
import {FormEtDroit} from "./scene2/2d/formEtDroit/FormEtDroit";
import {Graffiti} from "./scene2/2d/graffiti/Graffiti";
import {PorteGalerie} from "./scene2/2d/porteGalerie/PorteGalerie";
import {Triphase} from "./scene3/Triphase/Triphase";
import {Labyrinthe} from "./scene3/Labyrinthe/Labyrinthe";
import {Puzzle} from "./scene3/Puzzle/Puzzle";
import {Dissout} from "./scene3/Dissout/Dissout";
import {Etirer} from "./scene3/Etirer/Etirer";
import {Forms} from "./scene3/Forms/Forms";
import {Jeans} from "./scene3/Jeans/Jeans";
import {Oncle} from "./scene3/Oncle/Oncle";
import {PorteOncle} from "./scene3/porteOncle/PorteOncle";
import {Cassettes} from "./scene2/2d/cassettes/Cassettes";
import {Avenant3} from "./scene4/Avenant3/Avenant3";
import {Avenant2} from "./scene4/Avenant2/Avenant2";
import {Contract} from "./scene4/Contrat/Contract";
import {Mac} from "./scene4/Mac/Mac";
import {Portraits} from "./scene4/Portraits/Portraits";
import {Discs} from "./scene4/Discs/Discs";
import {Calc} from "./scene4/Calc/Calc";
import {Velo} from "./scene5/Velo/Velo";
import {Rings} from "./scene5/Rings/Rings";
import {Larry} from "./scene5/Larry/Larry";
import {Locker} from "./scene5/Locker/Locker";
import {RedBox} from "./scene5/RedBox/RedBox";
import {Barrillet} from "./scene6/barrillet/Barrillet";
import {Cross} from "./scene6/cross/Cross";
import {Tomate} from "./scene6/tomate/Tomate";
import {Sonate} from "./scene6/sonate/Sonate";
import {End} from "./scene6/end/End";
import {useCState} from "./CState";
import {galerieUnlockedKey} from "./scene2/360/Front/Front";
import {BankBox} from "./scene5/BankBox/BankBox";


export const page2dState = atom({
    key: 'page2dState',
    default: default2D,
});

const closeInfoKey = "closeInfoKey";

const page2ds = {
    Welcome,
    Frigo,
    Smartphone,
    Desktop,
    Acronym,
    FormEtDroit,
    Graffiti,
    Cassettes,
    PorteGalerie,
    Triphase,
    Labyrinthe,
    Puzzle,
    Dissout,
    Oncle,
    Etirer,
    Forms,
    Jeans,
    PorteOncle,
    Avenant2,
    Avenant3,
    Contract,
    Mac,
    Portraits,
    Discs,
    Calc,
    Velo,
    Rings,
    Larry,
    Locker,
    RedBox,
    Barrillet,
    Cross,
    Sonate,
    Tomate,
    End,
    BankBox,
}

export const Page2d = () => {
    const screenName = useRecoilValue(page2dState);
    const setPage2d = useSetRecoilState(page2dState);
    const [closeInfo, setCloseInfo] = useCState(closeInfoKey);

    // console.log("screen.name :", screenName)
    let ScreenComp = page2ds[screenName];
    // console.log("page2ds :", ScreenComp)

    const setGyroEnabled = useSetRecoilState(gyroEnabledState);

    useEffect(() => {
        if (ScreenComp)
            setGyroEnabled(false);
    }, [ScreenComp])

    if (ScreenComp) {
        return <div className={"page2d"} onWheel={(event)=> {
            event.stopPropagation();
        }}>
            <div className={isLand() ? "page2dInnerLand" : "page2dInnerPort"}>
                {screenName !== "Welcome" && <div style={{
                    display: "flex", justifyContent: "right"
                }}>
                    {!closeInfo && <div className={"blink-image"} style={{
                        display: "absolute",
                        margin: "1rem",
                        position: "absolute",
                        zIndex: '4',
                        right: "3.5rem",
                        fontSize: "1.5rem",
                        color: "yellow"
                    }}>
                        {"Click here to close =>"}
                    </div>}
                    <img style={{
                        display: "absolute",
                        width: "3rem",
                        margin: "0.5rem",
                        position: "absolute",
                        zIndex: '3',
                        right: 0
                    }}
                         src={require("./close.png").default}
                         className={"page2dClose"}
                         onClick={() => {
                             setCloseInfo(true);
                             setGyroEnabled(true);
                             setPage2d(null);
                         }}/>
                </div>}
                <ScreenComp/>
            </div>
        </div>
    } else
        // console.log("screens :", screens)

    return null
}