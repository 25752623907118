import React, {useEffect, useRef, useState} from "react";
import {useCState} from "../../CState";
import {completeResponse} from "../../config";
import {useSetRecoilState} from "recoil";
import {page2dState} from "../../Page2d";
import {gameStartedKey, gameStoppedKey} from "../../Chrono";
import {event, isLand, msToHMS, persistC} from "../../util";
import {Fireworks} from "fireworks-js/dist/react";
import Cookies from "js-cookie";
import moment from "moment/moment";
import {stopGame} from "../../server/toApi";

const options = {
    speed: 10,
    delay: {min: 0, max: 1},
    explosion: 10
}

const style = {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'fixed',
}
export const End = () => {


    const [team, setTeam] = useCState("team");

    useEffect(() => {
        stopGame().then(res => {
                // console.log("stopGame", res);
                if (res.team) {
                    persistC("team", res.team);
                    setTeam(res.team);
                }
            }
        )
    }, [])


    return (<div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%'
        }}
    >
        <Fireworks
            options={options} style={style}/>
        {team && <div
            className={"col"}
            style={{
                color: "white",
                border: "solid white 1px",
                background: "rgba(0,0,0,0.5)",
                zIndex: 1,
                textAlign: "center",
                ...(isLand() ? {
                    padding: "2vh",
                    fontSize: "3.5vh",
                    borderRadius: "2vh"
                } : {
                    padding: "2vw",
                    fontSize: "3.5vw",
                    borderRadius: "2vw",
                    margin: "2vw",
                })
            }}>

            Félicitation !<br/>
            Votre équipe a terminé le jeu.<br/>
            <div
                style={
                    {
                        fontSize: "25px",
                    }
                }
            >
                {team.cluesCount > 0 &&
                    <i>Vous avez utilisé {team.cluesCount} indice{team.cluesCount > 1 && "s"}, vous avez donc une
                        pénalité
                        de {team.cluesCount} X 30 minutes</i>}
                {team.cluesCount === 0 && <i>Vous n'avez pas utilisé d'indice, bravo !</i>}
            </div>

            <div
                style={{
                    fontSize: "50px",
                    fontWeight: "bold",
                    color: "yellow",
                    margin: "2vh",
                    fontFamily: 'Digital-7',
                }}
            >
                {team.cluesCount > 0 && <><Flex>
                    <div style={leftStyle}>JEU</div>
                    <div style={rStyle}>&nbsp;&nbsp;{team.gameDurationStr}</div>
                </Flex>
                    <Flex>
                        <div style={leftStyle}>INDICES
                        </div>
                        <div style={rStyle}>+ {team.cluesDurationStr}</div>
                    </Flex></>}
                <Flex>
                    <div style={leftStyle}>SCORE</div>
                    <div style={rStyle}>= {team.scoreDurationStr}</div>
                </Flex>
            </div>
            <div style={{
                fontSize:"20px",
                textAlign:"left",
            }}>
                <div style={{textAlign:"center"}}><b>Credits</b></div>
                <i>Université de Genève</i><br/>
                <u>Département de droit commercial</u><br/>
                Rita Trigo Trindade, Professeure<br/>
                Niccolò Gori, Assistant<br/>
                Vladimir Antipin, Assistant<br/>
                Ivan Dekker-Dos Santos, Assistant<br/>
                João Oselieri Lopes, Assistant<br/>
                Nicolas Demole, Auxiliaire de recherche<br/>
                <br/>
                <u>Pôle de Soutien à l'Enseignement et à l'Apprentissage</u><br/>
                Christophe Carleï<br/>
                <br/>
                Voix Annabelle : Clara Trigo Trindade<br/>
                Development & Design : beauvaisbruno@gmail.com<br/>
            </div>
        </div>
        }
    </div>)
}


const leftStyle = {
    width: "200px",
    textAlign: "right",
}

const rStyle = {
    width: "300px",
}


const Flex = ({children, style}) => {
    if (!style) style = {};
    return <div style={{
        display: "flex", alignItems: 'center',
        gap: "10px", ...style
    }}>{children}</div>
}